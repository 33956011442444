
<template>
<div class="card mb-3">
    <div class="card-body">
        <h4>Ketering</h4>
        <hr>

        <div v-if="settings_data">
            <div class="settings-group">

                <div class="global-settings-parameter option-field input-group">
                    <p class="setting-desc">Prijava za ketering je moguća do <small>(Nakon toga moguće je se prijaviti za samostalni obrok)</small></p>
                    <div class="settings-value">
                        <input type="time" class="form-control settings-value-time" v-model="settings_data.assign_end_time" name="assign-end-time" id="assign-end-time">
                    </div>
                </div>


                <div class="global-settings-parameter option-field input-group">
                    <p class="setting-desc">Uključi automatsko kreiranje porudžbine za ketering</p>
                    <select name="auto_order_enable" id="auto_order_enable" v-model="settings_data.auto_order_enable" class="form-control settings-round-value">
                        <option :value="true">Uključeno</option>
                        <option :value="false">Isključeno</option>
                    </select>
                </div>

                <div class="global-settings-parameter option-field input-group">
                    <p class="setting-desc">Prijava za ketering je moguća do <small>(Nakon toga moguće je se prijaviti za samostalni obrok)</small></p>
                    <div class="settings-value">
                        <input type="time" class="form-control settings-value-time" v-model="settings_data.assign_end_time" name="assign-end-time" id="assign-end-time">
                    </div>
                </div>


                <div class="global-settings-parameter option-field input-group">
                    <p class="setting-desc">Slanje porudzbinom preko SMS poruke restoranima</p>
                    <div class="settings-value">
                        <input type="time" class="form-control settings-value-time" v-model="settings_data.place_sms_order_at" name="assign-end-time" id="assign-end-time">
                    </div>
                </div>

                <div class="global-settings-parameter option-field input-group">
                    <p class="setting-desc">Uslov radnih sati za odobrenje samostalnog obroka</p>
                    <div class="input-sufix hour-sufix settings-round-value short-value">
                        <input type="number" min="0" max="19" step="1" class="form-control" v-model="settings_data.min_workhours_self_catering" name="min-workhours-self-catering" id="min-workhours-self-catering">
                    </div>
                </div>


                <div class="global-settings-parameter option-field input-group">
                    <p class="setting-desc">Omogućiti dodatni obrok ako radnik radi prekovremeno (sati)</p>

                    <div class="input-sufix hour-sufix settings-round-value short-value">

                        <input type="number" min="0" max="9" step="1" width="100" class="form-control" v-model="settings_data.overtime_self_catering" name="overtime-slef-catering" id="overtime-slef-catering">
                    </div>
                </div>

                <div class="global-settings-parameter option-field input-group">
                    <p class="setting-desc">Naknada za samostalni ketering</p>
                    <div class="d-relative">
                        <div class="input-sufix rsd-sufix short-value">
                            <input type="number" min="0" step="1" max="99999" class="form-control" v-model="settings_data.self_catering_price" name="self-catering-price" id="self-catering-price">
                        </div>
                    </div>
                </div>


            </div>

            <button @click="saveSettings" class="btn btn-primary">Sačuvaj</button>
        </div>
.

    </div>
</div>
</template>

<script>

    import SettingsService from '@/service/SettingsService.js';
    import {mapState} from 'vuex'

    export default {
        
        name: "SettingsGeneral",

        components: {
        },

        data() {
            return {
                settings_data: {
                    assign_end_time: "7:30",
                    min_workhours_self_catering: 8,
                    overtime_self_catering: 2,
                    self_catering_price: 400,
                    auto_order_enable: true,
                }

                // settings_data: null

            }
        },

        computed: {
            ...mapState([
                'settings',
            ]),

        },


        methods: {
            async loadSettingsData(){
                await this.$store.dispatch('loadSettings');
                console.log(this.settings);

                let settings_data = {}
                this.settings.forEach(opt => {
                    if (opt.section != 'catering') return

                    settings_data[opt.name] = opt.value
                });

                this.settings_data = settings_data
            },

            async saveSettings() {
                let payload = {}

                Object.entries(this.settings_data).forEach(entry => {
                    
                    if (entry[1] != true && entry[1] != false && !isNaN(entry[1])) {
                        entry[1] = parseFloat(entry[1]) 
                    }

                    payload["catering__" + entry[0]] = entry[1]
                })

                try {
                    await SettingsService.updateSettings(payload);
                    this.$toasted.show("Sačuvano", { 
                        type: 'success',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });

                } catch {
                    this.$toasted.show("Neuspešno čuvanje", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }





            }
        },

        created() {
            this.loadSettingsData();
        },

    }
</script>
